@import "styles/variables";
@import "/node_modules/@forgerock/backstage-ui-components";
@import "/node_modules/bootstrap/scss/bootstrap";
@import "/node_modules/bootstrap-icons";

html {
  position: relative;
  min-height: 100%;
}

.rounded-xl {
  border-radius: $btn-border-radius;
}

.grayscale {
  filter: grayscale(1);
}

h1, h2, h3, h4, h5 {
  font-weight: bold;
  color: $backstage-color-ping-gray;
}
